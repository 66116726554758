import '../../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import jsNav from './jsscripts';


// const body = document.querySelector("body"),
    
        //   toggle = body.querySelector(".toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard")

          
// function toggleClass() {
//     const sidebar = document.querySelector(".sidebarFIRSTCOMPUTER_Unit2ChoiceBoard");
//     sidebar.classList.toggle("closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard");
// }

function NavBar() {

    // const body = document.querySelector("body"),
    //       sidebar = body.querySelector(".sidebarFIRSTCOMPUTER_Unit2ChoiceBoard"),
    //       toggle = body.querySelector(".toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard");

    // toggle.addEventListener("click", () => {
    //     sidebar.classList.toggle("closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard");
    // });

    const togSideBar = () => {
        const body = document.querySelector("body"),
            sidebar = body.querySelector(".sidebarFIRSTCOMPUTER_Unit2ChoiceBoard"),
            toggle = body.querySelector(".toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard");

        sidebar.classList.toggle("closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard");
    }

    const currLink = "/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History/Unit2_ChoiceBoard/First_Computer";
    const homeLink = "/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History/Unit2_ChoiceBoard";

  return (
    <div>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />        
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

    <nav className='sidebarFIRSTCOMPUTER_Unit2ChoiceBoard'>
            <header className='headerFIRSTCOMPUTER_Unit2ChoiceBoard'>
                
                    <Link to={homeLink} onClick={() => window.setTimeout(function()
          {window.location.reload()
           },100)} className='homeLinkHOMEFIRSTCOMPUTER_Unit2ChoiceBoard'>
                        <div className="image-textFIRSTCOMPUTER_Unit2ChoiceBoard">
                        <span className='imageNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <span class="material-symbols-outlined homeIconFIRSTCOMPUTER_Unit2ChoiceBoard">
                                home
                            </span>
                        </span>
                        <div className="textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard headerTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard">
                            <span className='HistoricalTimelineNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                Home
                            </span>
                        </div>
                        </div>
                    </Link>
                    
                

                
                <i class='bx bx-chevron-right toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard' onClick={togSideBar}></i>
            </header>

            <div className='menu-barFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <div className="menuNavBarFIRSTCOMPUTER_Unit2ChoiceBoard">
                    <hr className='breakLine1navBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                    </hr>

                    <ul className='menuLinksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/Alan_Turing_Timeline"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-1 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    Alan Turing Timeline
                                </span>
                            </Link>
                        </li>
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/Related_Contributors"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-2 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    Related Contributors
                                </span>
                            </Link>
                        </li>
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/World_War_2"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-3 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    World War II
                                </span>
                            </Link>
                        </li>
                        
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/Other_Contributions"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-4 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    Other Contributions
                                </span>
                            </Link>
                        </li>
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/Legacy"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-5 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    Legacy
                                </span>
                            </Link>
                        </li>
                        <li className='navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                            <Link to={currLink+"/References"} className='linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                <i class='bx bx-dice-6 iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'></i>
                                <span className='textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard'>
                                    References
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>

                
            </div>
        </nav>
        {/* <script src={jsNav}></script> */}
        
    </div>
  )
}

export default NavBar
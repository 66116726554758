import '../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Home_Unit2_ChoiceBoard from './Unit2_ChoiceBoard/Home';

function CinemaAsAWitnessToModernHistory() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History">
            <div>Cinema As A Witness to Modern History</div>
          </Route>
          <Route path="/School_Projects_Home/Grade12/Cinema_As_A_Witness_To_Modern_History/Unit2_ChoiceBoard">
            <Home_Unit2_ChoiceBoard></Home_Unit2_ChoiceBoard>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default CinemaAsAWitnessToModernHistory
import '../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Grade12 from './Grade12/Grade12';

function SchoolProjectsHome() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/School_Projects_Home">
            <div>SchoolProjectsHome2</div>
          </Route>
          <Route path="/School_Projects_Home/Grade12">
            <Grade12></Grade12>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default SchoolProjectsHome
import '../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";

import TwoRiaSharon from './AudioClips/1.Sharon-When I was your man.mp4';
import SixFaithAyushi from './AudioClips/Faith Ayushi.mp3';
import NineRia from './AudioClips/Ria- Stay (Karaoke Version).mp3';
import TenCelinaJingyiOphelia from './AudioClips/(G)I-DLE - TOMBOY first part.mp3';
import TwelveRaidon from './AudioClips/Raidon - plastic love.mp3';
import EighteenSukhleenRImiSenuliArshia from './AudioClips/Senuli.Rimi.Sukleen.mp4';
import NineteenTanveerSotirisSabbitClintonIni from './AudioClips/Promise.mp3';
import TwentyYumnaKayeleenHarshitaSonalSpreeha from './AudioClips/Harshita remix.mp3';
import TwentythreeKennedy from './AudioClips/kennedy-D1_5_.mp3';
import TwentyfiveDanielRia from './AudioClips/Rewrite_the_stars_lower_key.mp3';
import TwentysevenJazzCorwinMonteEvanJosh from './AudioClips/-Sweet Caroline.mp3';
import SukhleenSecondVid from './AudioClips/75D0562C-C6F3-4EFE-A4C0-FCA1B7EE61E4.mp4';
import SeventeenRomeo from './AudioClips/Romeo and Romeos Theme.mp4';
import MayuriIntro from './AudioClips/MIB - Mayuri MC.mp3';

import SpreehaKayleenMusic from './AudioClips/pink + white.m4a';

import EighteenSkit from './AudioClips/Skit.mp4';
import RihannaScreenShot from './AudioClips/Screen Shot 2023-04-26 at 6.20.12 PM.png';

import TomboyCelina from './AudioClips/(G)I-DLE - TOMBOY.mp3';
import TanveerGroupMusic from './AudioClips/Grad_Show_AudioProd_Ini (2).wav';


import GameShowMusic from './AudioClips/The Game Show Theme Music.mp3';
import Cheering from './AudioClips/Cheering.mp3';
import Buzzer from './AudioClips/Buzzer.mp3';
import Boo from './AudioClips/boo.mp3';
import Ooh from './AudioClips/ooh.mp3';
import SitcomLaughter from './AudioClips/Sitcom Laughter Applause - Gaming Sound Effect (HD).mp3';

import Baby from './AudioClips/Baby.mp3';
import OCanada from './AudioClips/O Canada.mp3';
import RihannaSuperBowl from './AudioClips/Rihanna Super Bowl.mp3';

import YungGravy from './AudioClips/Yung Gravy - Betty Get Money Clean.mp3';
import OutroGuitar from './AudioClips/Outro Guitar Sound Effect.mp3';

import Mariobros from './AudioClips/new-super-mario-bros-wii-music-extended.mp3';

// import Canned

// import Home_Unit2_ChoiceBoard from './Unit2_ChoiceBoard/Home';

function GradShow2023() {

    let workIconStyles = {background: "#06D6A0"};
    let schoolIconStyles = {background: "#f9c74f"};

    let firstComputerTimelineElements = [
        {
            id: 1,
            names: "1. Aieshini, Yewon",
            title: "The Swan (Piano & Cello Duet)",
            description1:
                "Soft Stage Light",
            icon: "work",
        },
        {
            id: 2,
            names: "2. Ria, Sharon A.",
            title: "When I was ur man (Singing)",
            description1:
                "Spotlight",
            icon: "work",
            audio: TwoRiaSharon,
        },
        {
            id: 3,
            names: "3. Olivia, Jazz, Corwin, Rylee, Josh, Kennedy, Monte (Drama Kids)",
            title: "First Aid Fail (Skit)",
            description1:
                "- No Audio -",
            icon: "work",
            // audio: TwoRiaSharon,
        },
        {
            id: 4,
            names: "4. Todd",
            title: "Singing and Guitar",
            description1:
                "Spotlight Needed, Increase Mic Volume a lot",
            icon: "work",
            // audio: TwoRiaSharon,
        },
        {
            id: 5,
            names: "5. Moinak, Gordon, Nathaniel, Kunvar",
            title: "(Skit)",
            description1:
                "When suspects come in, all lights off",
            icon: "work",
            // audio: TwoRiaSharon,
        },
        {
            id: 6,
            names: "6. Faith, Ayushi",
            title: "Mashup (Dance)",
            description1:
                "1:13 Faith ---- 1:24 Ayushi ---- 1:33 Faith ---- 1:38 Ayushi",
            icon: "work",
            audio: SixFaithAyushi,
        },
        {
            id: 7,
            names: "7. Daniel",
            title: "Land of Dreams (Singing)",
            description1:
                "- No Audio -",
            icon: "work",
            // audio: TwoRiaSharon,
        },
      ];

      let secondComputerTimelineElements = [
        {
            id: 9,
            names: "9. Ria",
            title: "Stay (Singing)",
            // description1:
            //     "- No Audio -",
            icon: "work",
            audio: NineRia,
        },
        {
            id: 10,
            names: "10. Celina, Jingyi, Ophelia",
            title: "(Dance)",
            icon: "work",
            description1: "Get ready to fade out",
            audio: TomboyCelina,
        },
        // {
        //     id: 11,
        //     names: "11. Grad Committee",
        //     title: "Mean Tweets",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        {
            id: 12,
            names: "11. Raidon",
            title: "Singing",
            icon: "work",
            audio: TwelveRaidon,
        },
      ];

      let thirdComputerTimelineElements = [
        {
          id: 16,
          names: "13. Xiangru",
          title: "Gravity (Singing)",
          description1:
              "- No Audio -",
          icon: "work",
          // audio: TwoRiaSharon,
      },
      {
        id: 17,
        names: "14. Jazz Monte, Josh, Corwin",
        title: "Romeo & Juliet (skit)",
        description1:
            "- No Audio -",
        icon: "work",
        audio: SeventeenRomeo,
    },
        {
            id: 'intermission',
            names: "Intermission",
        },
        {
            id: 14,
            names: "15. Sotiris, Promise, Ben, Jacob, Gordon, Xiangru",
            title: "Theme from Taxi Driver & A Taste of Honey (Band)",
            description1:
                "- No Audio -",
            icon: "work",
            // audio: TwoRiaSharon,
        },
        {
          id: 22,
          names: "16. Spreeha, Kayeleen",
          title: "Singing",
          description1:
              "- No Audio - and One Spotlight",
          icon: "work",
          audio: SpreehaKayleenMusic,
      },
        // {
        //     id: 15,
        //     names: "15. Sharon & Ibukun",
        //     title: "Dance",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        
        
        // {
        //     id: 18,
        //     names: "18. Sukhleen Rimi Senuli Arshia",
        //     title: "Dance",
        //     icon: "work",
        //     description1: "Dark from 2:05 to 2:14 stagelight          1) Until 0:18 the center light thing         2)After all the lights   3)2:11 lights go out       4)2:21 light come back out",
        //     audio: EighteenSukhleenRImiSenuliArshia,
        // },
        // {
        //     id: 19,
        //     names: "19. Tanveer, Sotiris, Sabbir, Clinton, Ini",
        //     title: "Rap",
        //     icon: "work",
        //     audio: NineteenTanveerSotirisSabbitClintonIni,
        // },
        // {
        //     id: 20,
        //     names: "20. Yumna, Kayeleen, Harshita, Sonal, Spreeha",
        //     title: "Glowstick Dance",
        //     description1:
        //         "No Light - glowstick dance",
        //     icon: "work",
        //     audio: TwentyYumnaKayeleenHarshitaSonalSpreeha,
        // },
        // {
        //     id: 21,
        //     names: "21. Evan",
        //     title: "Livin' on a Prayer (Guitar)",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        // {
        //     id: 22,
        //     names: "22. Daniel, Spreeha, Kayeleen",
        //     title: "Singing",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        // {
        //     id: 23,
        //     names: "23. Kennedy",
        //     title: "Acro Contemporary (Dance)",
        //     icon: "work",
        //     audio: TwentythreeKennedy,
        // },
        // {
        //     id: 24,
        //     names: "24. Kunvar, Xiangru",
        //     title: "Changes (Singing)",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        // {
        //     id: 25,
        //     names: "25. Daniel, Ria",
        //     title: "Rewrite the Stars (Singing)",
        //     icon: "work",
        //     audio: TwentyfiveDanielRia,
        // },
        // {
        //     id: 26,
        //     names: "26. Tofu",
        //     title: "What on Earth? (skit) Rihanna Superbowl Performance (Dance)",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        
      ];

      let fourthComputerTimelineElements = [
        {
            id: 19,
            names: "18. Tanveer, Sotiris, Sabbir, Clinton, Ini",
            title: "Rap",
            icon: "work",
            description1:"1) Change aux cable from mac to wall. 2) Change sound setting from speaker to Epson Projector. 3) End of video change audio settings ---------- 2:10 countdown (lights off) and after countdown turn back on. ------------------ Dim Stage lights at countdown and but back on when countdown ends.",
            audio: TanveerGroupMusic,
        },
        {
            id: 20,
            names: "19. Yumna, Kayeleen, Harshita, Sonal, Spreeha",
            title: "Glowstick Dance",
            description1:
                "No Light - glowstick dance",
            icon: "work",
            audio: TwentyYumnaKayeleenHarshitaSonalSpreeha,
        },
        {
            id: 21,
            names: "20. Evan",
            title: "Livin' on a Prayer (Guitar)",
            description1:
                "- No Audio -",
            icon: "work",
            // audio: TwoRiaSharon,
        },
        // {
        //     id: 22,
        //     names: "22. Daniel, Spreeha, Kayeleen",
        //     title: "Singing",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        {
          id: 24,
          names: "21. Kunvar, Xiangru",
          title: "Changes (Singing)",
          description1:
              "- No Audio -",
          icon: "work",
          // audio: TwoRiaSharon,
      },
        {
            id: 23,
            names: "22. Kennedy",
            title: "Acro Contemporary (Dance)",
            icon: "work",
            audio: TwentythreeKennedy,
        },
        
        {
            id: 25,
            names: "23. Daniel, Ria",
            title: "Rewrite the Stars (Singing)",
            icon: "work",
            audio: TwentyfiveDanielRia,
        },
        // {
        //     id: 26,
        //     names: "26. Tofu",
        //     title: "What on Earth? (skit) Rihanna Superbowl Performance (Dance)",
        //     description1:
        //         "- No Audio -",
        //     icon: "work",
        //     // audio: TwoRiaSharon,
        // },
        
      ];

      let fifthComputerTimelineElements = [
        {
            id: 27,
            names: "25. Jazz, Corwin, Monte, Evan, Josh",
            title: "Sweet Caroline (singing)",
            icon: "work",
            audio: TwentysevenJazzCorwinMonteEvanJosh,
        },
      ];


      let BestFriendShow = [
        {
            id: 1,
            names: "8. Best Friend",
            title: "(skit)",
            icon: "work",
            description1:
                "The Game Show Theme Music.mp3",
            audio1: GameShowMusic,
            description2: 
                "Sitcom Laughter Applause - Gaming Sound Effect (HD).mp3",
            audio2: SitcomLaughter,
            description3: 
                "Cheering.mp3",
            audio3: Cheering,
            description4: 
                "ooh.mp3",
            audio4: Ooh,
            description5: 
                "boo.mp3",
            audio5: Boo,
            description6: 
                "Buzzer.mp3",
            audio6: Buzzer,
        },
      ];

      let NathanielShow = [
        {
            id: 1,
            names: "12. Ritch Nathaniel",
            title: "Stand Up Comedy",
            icon: "work",
            description1:
                "Cheering.mp3",
            audio1: Cheering,
            description2: 
                "Sitcom Laughter Applause - Gaming Sound Effect (HD).mp3",
            audio2: SitcomLaughter,
            description3: 
                "Yung Gravy - Betty Get Money Clean.mp3",
            audio3: YungGravy,
            description4: 
                "Outro Guitar Sound Effect.mp3",
            audio4: OutroGuitar,
            description5: "new-super-mario-bros-wii-music-extended.mp3",
            audio5: Mariobros,
        },
      ];

      let RihannaShow = [
        {
            id: 1,
            names: "24. Rihanna Superbowl Performance",
            title: "(skit)",
            icon: "work",
            description1:
                "The Game Show Theme Music.mp3",
            audio1: GameShowMusic,
            description2: 
                "Sitcom Laughter Applause - Gaming Sound Effect (HD).mp3",
            audio2: SitcomLaughter,
            description3: 
                "Cheering.mp3",
            audio3: Cheering,
            description4: 
                "ooh.mp3",
            audio4: Ooh,
            description5: 
                "boo.mp3",
            audio5: Boo,
            description6: 
                "Buzzer.mp3",
            audio6: Buzzer,
            description7: 
                "Baby.mp3",
            audio7: Baby,
            description8: 
                "O Canada.mp3",
            audio8: OCanada,
            description9: 
                "Rihanna Super Bowl.mp3",
            audio9: RihannaSuperBowl,
        },
      ];

      let SukhleenShow = [
        {
            id: 18,
            names: "17. Sukhleen",
            title: "(skit)",
            icon: "work",
            description1:
                "DO NOT USE THIS ONE USE NEXT CLIP Dark from 2:05 to 2:14 stagelight ",
            audio1: EighteenSukhleenRImiSenuliArshia,
            description2: 
                "1) Until 0:18 the center light thing         2)After all the lights   3)2:11 lights go out       4)2:21 light come back out------After white song spotlight on Arishia",
            audio2: SukhleenSecondVid,
            
        },
      ];



  return (
    <Router>
      <div className="content GradShow2023">
        <Switch>
          <Route exact path="/School_Projects_Home/Grade12/GradShow2023">
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Grad Show 2023!!</h1>
            <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard'>Act List</p>
            
            
            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard'>


            <h3>Mayuri Intro Music</h3>
            <audio controls>
              
                        <source src={MayuriIntro} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                </audio>
            

            {firstComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {BestFriendShow.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio1} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <audio controls>
                        <source src={element.audio2} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                    <audio controls>
                        <source src={element.audio3} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description4}
                    </p>
                    <audio controls>
                        <source src={element.audio4} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description5}
                    </p>
                    <audio controls>
                        <source src={element.audio5} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description6}
                    </p>
                    <audio controls>
                        <source src={element.audio6} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>


            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {secondComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {NathanielShow.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio1} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <audio controls>
                        <source src={element.audio2} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                    <audio controls>
                        <source src={element.audio3} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description4}
                    </p>
                    <audio controls>
                        <source src={element.audio4} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description5}
                    </p>
                    <audio controls>
                        <source src={element.audio5} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>
            
            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {thirdComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {SukhleenShow.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio1} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <audio controls>
                        <source src={element.audio2} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    
                    
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            <video width="70%" controls className="GradShowEighteenSkitGradShow">
              <source src={EighteenSkit} type ="video/mp4"></source>
                          Browser Does Not Support Audio Element...
            </video>

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {fourthComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            
            

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {RihannaShow.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio1} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <audio controls>
                        <source src={element.audio2} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                    <audio controls>
                        <source src={element.audio3} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description4}
                    </p>
                    <audio controls>
                        <source src={element.audio4} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description5}
                    </p>
                    <audio controls>
                        <source src={element.audio5} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description6}
                    </p>
                    <audio controls>
                        <source src={element.audio6} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description7}
                    </p>
                    <audio controls>
                        <source src={element.audio7} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description8}
                    </p>
                    <audio controls>
                        <source src={element.audio8} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description9}
                    </p>
                    <audio controls>
                        <source src={element.audio9} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>

            <img src={RihannaScreenShot} width="50%" className='GradShowEighteenSkitGradShow'></img>

            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard BestFriendSkitGradShow2023'>
            {fifthComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.names}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.title}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <audio controls>
                        <source src={element.audio} type ="audio/ogg"></source>
                        Browser Does Not Support Audio Element...
                    </audio>
                    <br/>
                    <br/>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1"> Completed</label>
                  </VerticalTimelineElement>
                );
              })}
            </div>
            
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default GradShow2023
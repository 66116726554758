import '../../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

// import computerImg from './images/Bletchley-replica-bombe.jpg';
import OneBP from './images/001BandProj.png';

import HistoricalTimeline from './FirstComputer';

function Home() {

  const currLink = "/School_Projects_Home/Grade12/BandFinalProject/BFP";


  return (
    <Router>
      <div className="content HomeCinemaUnit2">
        <Switch>
          <Route exact path={currLink}>
            <div>
              <div className='introTextUnit2ChoiceBoard'>
                <h1 className='HomeTextUnit2ChoiceBoard'>Band Final Project</h1>
                <h2 className='HomeNameUnit2ChoiceBoard'>Arnab Mandal</h2>
                <hr className='line1Unit2ChoiceBoard' width="70%" align="center"></hr>
              </div>
              
              
          
              

              <div className='linksandButtonsHomeCinemaUnit2ChoiceBoard'>
                {/* <h1>Scroll down to explore!</h1> */}
                <h3 className='homeComputerIntroTextCinemaUnit2ChoiceBoard'>Arnab's Band Experience</h3>
                <div className='wrapper1HomeUnit2ChoiceBoard'>
                  <div className='computerIMGUnit2ChoiceBoardWrapper'>
                    <img src={OneBP} className="computerIMGUnit2ChoiceBoard"/>
                  </div>
                  <div className='textComputer2Unit2ChocieBoard'>
                    {/* <h2>ALign Text</h2> */}
                    <p>Learn about Arnab's experience throughout the Fort Richmond Band Program!</p>
                    <Link to={currLink+"/Project"}>
                      <button className='FirstComputerHomeButtonUnit2ChoiceBoard'>
                        Learn More
                      </button>
                    </Link>
                    
                  </div>
                </div>

                
                
              </div>
              
            </div>
          </Route>
          <Route path={currLink+"/Project"}>
            <HistoricalTimeline></HistoricalTimeline>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Home


// return (
//     <Router>
//       <div className="content">
//         <Switch>
//           <Route exact path="/">
//             <div>
//               <div>
                
//                 <Link to="/invention_of_computer">
//                   <button className="homeButtons">
//                     <img src={computerImg}></img>
//                     Invention of the Computer
//                   </button>
//                 </Link>
//               </div>
//               <div>
//                 <Link to="/another_issue">
//                   <button>
//                     Another Issue
//                   </button>
//                 </Link>
//               </div>
              
              
//             </div>
//           </Route>
//           <Route path="/invention_of_computer">
//             <h1>Computer Invention</h1>
//           </Route>
//           <Route path="/another_issue">
//             <h1>ANOTHER ISSUE</h1>
//           </Route>
//         </Switch>
//       </div>
      
//     </Router>
    
//   );


// <div className="Button1HomeCinemaUnit2ChoiceBoard">
//                   <Link to={currLink+"/invention_of_computer"}>
//                     <button className="homeButtonsCinemaUnit2ChoiceBoard">
//                       <img src={computerImg} className="image1HomeChinemaUnit2ChoiceBoard"></img>
//                       <h1>Invention of the Computer</h1>
//                     </button>
//                   </Link>
//                 </div>
//                 <div>
//                   <Link to={currLink+"/anotherIssue"}>
//                     <button className="homeButtonsCinemaUnit2ChoiceBoard">
//                       Another Issue
//                     </button>
//                   </Link>
//                 </div>
import '../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Grade12 from './Grade12/Grade12';
import Fall2023 from './Fall2023/Fall2023';

function UniversityProjectsHome() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/University_Projects_Home">
            <div>University Projects</div>
          </Route>
          <Route path="/University_Projects_Home/Fall2023">
            <Fall2023></Fall2023>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default UniversityProjectsHome
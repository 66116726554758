import '../../../../../App.css';
import './SeatingSelection.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';


// import CinemaAsAWitnessToModernHistory from './Cinema/CinemaAsAWitnessToModernHistory';
// import GradShow2023 from './GradShow2023/GradShow2023';
// import BandFinalProject from './BandFinalProject/BandFinalProject';
import SeatingArrangements from './media/SeatingArrangements.png';

function SeatingSelection() {

    function GiveAccessibleSeatingOptions() {
        var AccessibleSeatingOptions = ["A1","A2","A3","A4","A5","A6"];
        var MakeItem = function(X) {
            return <option>{X}</option>;
        };
        return <select>{AccessibleSeatingOptions.map(MakeItem)}</select>
    }

    function GiveCompanionSeatingOptions() {
        var AccessibleSeatingOptions = ["-- No Selection --","B1","B2","B3","B4","B5","B6","B7","B8","B9","B10","B11","B12","B13","B14","B15","B16","B17","B18","B19","B20","B21","B22"];
        var MakeItem = function(X) {
            return <option>{X}</option>;
        };
        return <select>{AccessibleSeatingOptions.map(MakeItem)}</select>
    }

  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/University_Projects_Home/Fall2023/APS111/Seating_Selection">
            <div className='PageTitle'>Hart House Theatre Seating Reservation Portal</div>
            <img className='SeatingImage' src={SeatingArrangements} alt="Seating Arrangements Image"></img>
            <form className='FormFill'>
                <div className='FormItem'>
                    <label>
                        Choice of Seating:
                        {GiveAccessibleSeatingOptions()}
                    </label>
                </div>
                <br/>
                <div className='FormItem'>
                    <label>
                        Optional - Choice of Companion Seating:
                        {GiveCompanionSeatingOptions()}
                    </label>
                </div>
                
                <br/>
                <div className='FormItem'>
                    <label>
                        First Name:
                        <input></input>
                    </label>
                </div>
                
                <br/>
                <div className='FormItem'>
                    <label>
                        Last Name:
                        <input></input>
                    </label>
                </div>
                
                <br/>

                <div className='FormItem'>
                    <label>
                        Email:
                        <input></input>
                    </label>
                </div>
                
                <br/>
                <div className='FormItem'>
                    <label>
                        Optional - Phone Number:
                        <input></input>
                    </label>
                </div>
                
                <br/>
                <div className='FormItem'>
                    <input type="submit" value="Submit" className='submitBtn' />
                </div>
                
            </form>

            <div className='MakeChanges'>
                <a href='' className='MakeChangesText' >Make Changes to your Reservation</a>
            </div>
            
          
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default SeatingSelection
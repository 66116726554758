import '../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Grade12 from './Grade12/Grade12';
// import Fall2023 from './Fall2023/Fall2023';
import Pakhi from './Pakhi/Pakhi';
import Yutong from './Yutong/Yutong';
import Juee from './Juee/Juee';
import Teagan from './Teagan/Teagan';
import Aarnav from './Aarnav/Aarnav';

function People() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/People">
            <div>People</div>
          </Route>
          <Route exact path="/People/Pakhi">
            <Pakhi></Pakhi>
          </Route>
          <Route exact path="/People/Yutong">
            <Yutong></Yutong>
          </Route>
          <Route exact path="/People/Jue">
            <Juee></Juee>
          </Route>
          <Route exact path="/People/Teagan">
            <Teagan></Teagan>
          </Route>
          <Route exact path="/People/Aarnav">
            <Aarnav></Aarnav>
          </Route>
          <Route path="/University_Projects_Home/Fall2023">
            {/* <Fall2023></Fall2023> */}
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default People
import '../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Home_BandFinalProject from './BFP/Home';

function BandFinalProject() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/School_Projects_Home/Grade12/BandFinalProject">
            <div>BandFinalProject</div>
          </Route>
          <Route path="/School_Projects_Home/Grade12/BandFinalProject/BFP">
            <Home_BandFinalProject></Home_BandFinalProject>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default BandFinalProject
import React from 'react';

import NavBar from './NavBar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import firstComputerTimelineElements from './firstComputerTimelineElements';
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import Home from './Home';

import DifferenceEngine from './images/1200px-Babbage_Difference_Engine.jpg';
import TuringComputer from './images/Bletchley-replica-bombe.jpg';
import Z3Computer from './images/zuse-z3-2.jpg';
import KingsCollegeLondon from './images/Screenshot-2021-02-28-at-18.33.13.png';
import DavidHilbert from './images/1_ahy12g2hFP21x7YolyQ_Ug.jpeg';
import UniversalTuringMachine from './images/artificialmusclecomputer1.jpg';
import PrincetonUniversity from './images/960x0.jpg';
import BletchleyParkCodeBreakers from './images/7244576-6479081-image-a-38_1544459200655.jpg';
import Hut8 from './images/_Hut_8_,_Bletchley_Park_-_geograph.org.uk_-_1592868.jpg';
import Tunny from './images/Screenshot 2023-01-01 155959.jpg';
import TuringHomosexual from './images/15.jpg';
import NPLLogo from './images/NPLLogo_360x222.png';
import TuringTest from './images/Turing-Diagram-159676.png';
import ChessProgram from './images/turing07-computer.jpg';
import CourtDocumentTuring from './images/sentencesm.png';
import TuringFigure from './images/1200px-Alan_Turing-1024x768.jpg';
import RoyalPardon from './images/s960_alan-turing-pardon.jpg';
import ENIAC from './images/Screenshot 2023-01-01 171958.jpg';
import ManchesterBaby from './images/large_cd0639_026_101203_GWW_RMG_Baby_computer.jpg';
import Enigma from './images/Enigma_(crittografia)_-_Museo_scienza_e_tecnologia_Milano.jpg';
import UBoat from './images/U-boat-FEATURED.jpg';
import Colossus from './images/Screenshot 2023-01-01 173658.jpg';
import TheImitationGame from './images/Screenshot 2023-01-01 173959.jpg';
import AutomaticComputingEngine from './images/ae0c545448af596a29cb5e175124bf42.jpg';


function First_Computer() {

  let workIconStyles = {background: "#06D6A0"};
  let schoolIconStyles = {background: "#f9c74f"};

  let firstComputerTimelineElements = [
    {
        id: 1,
        title: "Charles Babbage",
        location: "London, England",
        description1:
            "Babbage had the idea of calculating mathematical tables in the early 1810s. The full design would be a room size device called the Difference Engine. At the time there still needed to be advances in some mechanical engineering techniques which Babbage spent time improving. The Difference Engine was not built by Babbage, but later in 1991, British scientists built the version of the Difference Engine and worked as Babbage hoped. The Difference machine could calculate and tabulate polynomial functions.",
        description2: " In 1834, Charles Babbage made the design of the Analytical Engine which was much more complex than the Difference Engine. The machine would have the capacity to hold 1000, fifty-digit numbers. This is far more than any computer built before 1960, yet the design was proposed by Babbage in 1834.",
        description3: "Unfortunately, the Babbage Analytical Engine was never built, but the Science Museum in London displays the drawing boards of Babbage's design.",
        reference1: "https://www.britannica.com/biography/Charles-Babbage",
        reference2: "https://www.nytimes.com/2011/11/08/science/computer-experts-building-1830s-babbage-analytical-engine.html#:~:text=The%20machine%20on%20the%20drawing,inventor%20envisioned%20but%20never%20built.",
        date: "1810s to 1834",
        icon: "work",
        image: DifferenceEngine,
        imageDescription: "Babbage's Difference Engine built later in 1991.",
    },
    {
        id: 2,
        title: "Alan Turing",
        location: "Bletchley Park, UK",
        description1:
            "In 1936, Alan Turing proposed the theoretical machine called the Universal Turing Machine that could solve any problem given in a set of instructions. The Universal Turing Machine was an abstract computing machine using the essential logic principles of the digital computer. The Universal Turing Machine was built by Alan Turing when working on the Entscheidungsproblem. In simpler terms, the Entscheidungsproblem is a desicion problem using the axioms of math to find a way to decide if a formula in a given system is true or provable.",
        description2: "In 1941, during World War II, Alan Turing contributed to build a maching using an electro-mechanical system to decrypt the Enigma. The basis of the idea of the Bombe came from Marian Rejewski in 1938.",
        description3: "In 1945, Alan Turing joined the National Physical Laboratory (NPL) in London to create an electronic computer. There, he designed the Automatic Computing Engine, but his colleagues at NPL thought the design was too complicated and proceeded to build a smaller machine. Although NPL lost the race to build the first electronic computer, Turing's ideas heavily influenced the development of the first electronic computer at the University of Manchester.",
        date: "1936 to 1945",
        icon: "work",
        image: TuringComputer,
        imageDescription: "An image of the Bombe Alan Turing made.",
        reference1: "https://www.britannica.com/biography/Alan-Turing/Computer-designer",
    },
    {
      id: 3,
      title: "Konrad Zuse",
      location: "Berlin",
      description1:
          "Konrad Zuse built the Z3, which was the earliest fully functioning program-controlled machine. The machine was built in isolation and was intended to be used for aerodunamic calculations.",
      description2: "The first build was destroyed by bombing in Berlin, but was reconstructed in 1960s.",
      description3: "Today, the Z3 is displayed at the Deutsches Museum in Munich.",
      date: "1941",
      icon: "work",
      image: Z3Computer,
      imageDescription: "The Z3 displayed at Deutsches Museum.",
      reference1: "https://www.computerhistory.org/timeline/1941/",
  },
  {
    id: 4,
    title: "John Mauchly and Presper Eckert",
    location: "United States of America",
    description1:
        "John Mauchly and Presper Eckert built one of thefirst programmed all electronic computer completed in 1945.",
    description2: "The machine was fast, but lacked the ability to store programs. Each program required rewiring of the computer. This machine was called the ENIAC (Electronic Numerical Integrator and Computer).The importance of storing memory was unvield in this invention.",
    description3: "A patent was awarded for the ENIAC in 1964. The general concenpt of the computer, however, could not be patented.",
    date: "1945",
    icon: "work",
    image: ENIAC,
    imageDescription: "An image of the ENIAC (Electronic Numerical Integrator and Computer).",
    reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 5,
  title: "University of Manchester",
  location: "Manchester",
  description1:
      "The computer called the Manchester Baby was then built in 1948 by Frederic C. Williams, Tom Kilburn, and Geoff Tootill, which then had the ability to store programs in memory.",
  description2: "",
  description3: "",
  date: "1948",
  icon: "work",
  image: ManchesterBaby,
  imageDescription: "An image of the Manchester Baby that was built.",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
  ];

  let AlanTuringTimeline = [
    {
        id: 1,
        title: "King's College",
        location: "Cambridge, England",
        description1:
            "From 1931 to 1934, Alan Turing studied at King's College (University of Cambridge).",
        description2: "",
        description3: "",
        reference1: "https://www.biography.com/scientist/alan-turing",
        reference2: "",
        date: "1931 to 1934",
        icon: "work",
        image: KingsCollegeLondon,
        imageDescription: "Image of King's College in 1930s",
    },
    {
        id: 2,
        title: "Decision Problem",
        location: "King's College, Cambridge, England",
        description1:
            "While attending some lectures, Alan Turing encountered a problem by David Hilbert, a famous German mathematician. Hilbert at that time had proposed a problem known as the Decision Problem. Hilbert's idea was that every mathematical problem has a solution and wanted to find a method which would allow anyone to identify true mathematical statements and false mathematical statements.",
        date: "1935",
        icon: "work",
        image: DavidHilbert,
        imageDescription: "An image of David Hilbert",
        reference1: "https://www.britannica.com/biography/Alan-Turing/Computer-designer",
    },
    {
      id: 3,
      title: "Universal Turing Machine",
      location: "King's College, Cambridge, England",
      description1:
          "Alan Turing believed that to solve Hilbert's problem, a machine should be able to attack the problem, one which does not have any intelligence of its own. The Universal Turing Machine that Alan Turing proposed in 1936 involves an infinite paper tape and a pointer to write and erase symbols. The proposed machine was represented as a thought experiemnt that involves logical mechanisms, which was Alan Turing's area of expertise. This thought experiment in fact is the basis of computer science decades after the Universal Turing Machine was proposed.",
      date: "1936",
      icon: "work",
      image: UniversalTuringMachine,
      imageDescription: "A simple diagram of the Universal Turing Machine",
      reference1: "https://www.computerhistory.org/timeline/1941/",
  },
  {
    id: 4,
    title: "Princeton University",
    location: "United States of America",
    description1:
        "At Princeton, Alan Turing was conducting research on mathematical intuition. His interest in cryptography was initiated where he would learn to encrypt and decrypt messages.",
    date: "1938",
    icon: "work",
    image: PrincetonUniversity,
    imageDescription: "Image of Princeton University Today",
    reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 5,
  title: "British Codebreaking Service",
  location: "Bletchley Park, United Kingdom",
  description1:
      "The day after the Great Britian entered the World War II, Alan Turing had to return to UK, at the Bletchley Park, the headquarter of the British Codebreaking Service. Alan Turing joined a group of about 30, with one other mathematician, and several other linguistics.",
  date: "1939",
  icon: "work",
  image: BletchleyParkCodeBreakers,
  imageDescription: "An image of codebreakers working at Bletchley Park",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 6,
  title: "Hut 8",
  location: "Bletchley Park, United Kingdom",
  description1:
      "Alan Turing worked on the ENIGMA code, much of his time on his own. What was unique about Alan Turing's thoughts was that he realized that the code breaking task could be mechanized. Alan Turing made the machine called the Bombe which searched for the settings of the ENIGMA machines. This machine was kept secret and thousands of messages were decrypted.",
  description2: "Alan Turing looked at the probabilities of two letters of the same letter to line up at random. Captured German ships in 1941 gave Alan Turing more information to work with to decrypt ENIGMA.",
  date: "1941",
  icon: "work",
  image: Hut8,
  imageDescription: "An image of Hut 8 at Bletchley Park, where Alan Turing worked in",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 7,
  title: "Tunny",
  location: "Bletchley Park, United Kingdom",
  description1:
      "At the start of World War II, another encryption machine had been made, which the English called Tunny. Tunny was based on a sort of digital code used by typewriters rather than Morse code.",
  description2: "When Turing attempted to solve the code in fall of 1942, he was able to crack the messages within a few weeks. The Bombe's created earlier became outdated and new machines had to be built.",
  date: "1942",
  icon: "work",
  image: Tunny,
  imageDescription: "An image of the a recording of the start of a Tunny Message taken from www.AlanTuring.net",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 8,
  title: "Alan Turing's Homosexuality",
  location: "England",
  description1:
      "Alan Turing was not considered as a hero in 1945 because of his homosexuality, individuality, and the fact that he knew too much information. Authorities worried about him being a security threat.",
  date: "1945",
  icon: "work",
  image: TuringHomosexual,
  imageDescription: "An image of Alan Turing being recognized as \"Champions of Pride\"",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 9,
  title: "National Physics Laboratory",
  location: "London, England",
  description1:
      "Alan Turing created a plan for the potential world's first modern computer. However, the National Physics Laboratory did not let Alan Turing proceed with his idea.",
  date: "1945",
  icon: "work",
  image: NPLLogo,
  imageDescription: "Logo of National Physics Laboratory London",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 10,
  title: "Artificial Intelligence",
  location: "London, England",
  description1:
      "Alan Turing predicts the development of Artificial Intelligence involving artificial neural networks over the several decades that follow. Alan Turing creates the artificial intelligence test (Turing Test) which he called the Imitation Game in 1950.",
  date: "1948-1950",
  icon: "work",
  image: TuringTest,
  imageDescription: "A simple diagram of the Turing Test",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 11,
  title: "Computer Programs",
  location: "London, England",
  description1:
      "Alan Turing writes some of the first computer programs. In fact, the first chess program was written by him in 1950.",
  date: "1950",
  icon: "work",
  image: ChessProgram,
  imageDescription: "An image of the \"Paper Machine\" which Alan Turing made where a computer program was made to play chess.",
  reference1: "https://www.chess.com/article/view/computers-and-chess---a-history",
},
{
  id: 12,
  title: "Alan Turing in Court",
  location: "Manchester, United Kingdom",
  description1:
      "Alan Turing ends up in court due to gross indecency for having an affair with another man. He avoided jail by agreeing on hormone therapy.",
  date: "1952",
  icon: "work",
  image: CourtDocumentTuring,
  imageDescription: "An image of one of Alan Turing's court files",
  reference1: "https://www.chess.com/article/view/computers-and-chess---a-history",
},
{
  id: 13,
  title: "Found Dead",
  location: "Wilmslow, England",
  description1:
      "Alan Turing is found dead in his bedroom. It is believed by many that he committed suicide by eating a cyanide poisoned apple, but whether or not he committed suicide is unknown.",
  description2: "Hormone therapy had caused him to gain weight, grow breasts, and the worst part was that it affected his state of mind. He cannot think with as much concentration as he could before which was important for his work.",
  date: "June 8, 1954",
  icon: "work",
  image: TuringFigure,
  imageDescription: "A figure of Alan Turing made many years after his death",
  reference1: "https://www.chess.com/article/view/computers-and-chess---a-history",
},
{
  id: 14,
  title: "Royal Pardon",
  location: "",
  description1:
      "The queen grants Alan Turing a royal pardon.",
  date: "2013",
  icon: "work",
  image: RoyalPardon,
  imageDescription: "An image of Alan Turing's Royal Pardon letter",
  reference1: "https://www.chess.com/article/view/computers-and-chess---a-history",
},
  ];

  const currLink = "/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History/Unit2_ChoiceBoard/First_Computer";

  const homeLink = "/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History/Unit2_ChoiceBoard"

  return (
    <Router>
      <div className='mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard'>
      <NavBar></NavBar>
      <div className="mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard">
        <Switch>
          <Route exact path={currLink}>
            <h1>Click one of the links on the left to get started!</h1>
          </Route>
          <Route  path={currLink+"/Related_Contributors"}>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Related Contributors</h1>
            <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard'>The development of computers cannot be credited to a single person. Many people have worked on improving the early foundation of computers so that computers today are made possible. This page shows Alan Turing's contributions in perspective of other scientists who have worked on the early development of computers.</p>
            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard'>
            {firstComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img>
                    <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.imageDescription}
                    </p>
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.location}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </div>
            
          </Route>
          <Route  path={currLink+"/Alan_Turing_Timeline"}>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Alan Turing Timeline</h1>
            <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard'>The following timeline highlights key events in Alan Turing's life which includes events making him successful and shows the times of some of his key contributions to the world.</p>
            <VerticalTimeline>
            {AlanTuringTimeline.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                  <VerticalTimelineElement 
                    key={element.key}
                    date={element.date}
                    dateClassName = "dateFIRSTCOMPUTER_Unit2ChoiceBoard"
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img>
                    <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.imageDescription}
                    </p>
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.location}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
           </VerticalTimeline>
          </Route>
          <Route path={currLink+"/World_War_2"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard BombeTitleFIRSTCOMPUTER_Unit2ChoiceBoard'>World War II</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>In the movie, one of the most prominent sections was showing Alan Turing make an electromechanical machine to crack the Enigma. This page talks more about what is related to this machine Turing built, highlighting what Enigma was, about the machine itself, how the machine impacted World War II, how the war impacted the development of computers, and some inaccuracies when shown in the movie.</p>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              
              
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Enigma</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
                One of the most famous machines used by the Germans in World War II was the Enigma. The Enigma allowed the Germans to encrypt and decrypt messages to protect military communications.  The first version of the machine was invented by a German engineer named Arthur Scherbius back in 1918. The machine itself consists of a keyboard, a lampboard, internal rotors, and complex electronic circuitry. One of the main uses of the Enigma was in the U-boats where the locations of each boat must be kept secret. This way the Nazis could plan attacks on enemy ships without the enemy knowing any idea of the presence of the hidden U-boats and the complex encryption of the messages sent across the U-boats using the Enigma machine prevented enemies from knowing the plans of the Nazis. The Nazis even changed the Enigma settings each day so if the enemy even decrypted a message one day, the next day all that work would no longer be useful with the new settings on the Enigma. An outstanding 158,962,555,217,826,360,000 ways of encoding the Enigma was possible.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
                The Enigma is based on a simpler substitution encryption method where each letter is assigned to another. However, what added complexity to the Enigma machine was that each time a letter was mapped, the entire assignment of letters would be changed. This rearrangement of assigned letters was based on the internal wiring itself to change, altering the current flow in the wires.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={Enigma} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Bombe</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              In order to counteract the strategy used by the Germans, the British used the Enigma codes that they did have to help find methods to always be able to crack the Enigma. Alan Turing had a heavy interest in automating the decryption method, which is why he spent the bulk of his time when he started working at Bletchly Park on trying to create an automated machine that could decrypt any Enigma message. The initial idea of Bombe made by Alan Turing was based on a Polish cryptologist, Marian Rejewski who called it “bomba.” Alan Turing improved upon this design, and along with suggestions from another mathematician Gordon Welchman, the Bombe was made. The Bombe consisted of about 100 rotating drums with 10 miles of wire and around 1 million soldered connections inside of it. The first time the machine was able to crack the Enigma was on March 14, 1940. It would take around 2-4 hours for the code to be broken, and thousands of messages could be intercepted and decoded each day. How the machine worked was that it used a brute force method and stopped only until no letter matched with itself.  What came very useful for speeding up the process of the Bombe finding a solution was that each letter could not be coded as itself and that the German messages sent had a “crib.” A “crib” is a piece of german text placed at familiar points in the message. With the help of the “crib,” the search could only look through upwards of 17500 combinations.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The invention of the Bombe by Turing is believed to have shortened the war by 2-4 years and since millions of people died from the war each year, the Bombe possibly may have saved 14-21 million lives. Without the Bombe, the D-Day landings would have been delayed, when the UK, US, Canada, and France, attacked German forces on the northern coast of France in 1944. The more the delay, the more time Hitler would have to prepare for more attacks.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={TuringComputer} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>How the Bombe impacted World War II</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The Bombe invention was very important in stopping World War II. The first Bombe was made in Britain by Turing and Welchman. Unfortunately, in Britain, during the war, there were production shortages. For this reason, the British found it best to reveal the design to America in 1942. Using the design given, Joe Desch was able to improve the design, and America produced many of their own Bombes. Joe Desch was able to incorporate his ideas to build on top of Alan Turing’s and Welchman’s ideas to make the Bombe much faster and more reliable. The American design started being used in mid-1943, and by December 1943, there were 120 machines installed, especially in ships. By knowing the locations of enemy ships, the ships coming from America to Britain could avoid coming near the German hidden U-boats, and make their way safely into Britain. Thousands of lives were saved this way by preventing attacks.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={UBoat} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>How World War II impacted the development of computers</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Just as the invention of the Bombe impacted World War II by shortening it, the war also accelerated the development of computers. At the time of World War II, there was a crucial need for automated codebreakers. At the time, all the messages being intercepted had to be decoded by hand. An automated was built to speed up the process. Alan Turing and his team were able to show that an automatic method is possible when inventing the Bombe, and that led to the invention of more advanced computers. When the Germans started sending messages by encoding the messages in what the English called “Tunny,” the Colossus was built. Inspired by the success of using an electromechanical device, The Bombe, to decrypt Enigma, Tommy Flowers built the Colossus. Additionally, in America, the Electronic Numerical Integrator and Computer (ENIAC) was also being developed for military purposes for World War II, but by the time the computer was finished being built, the war had stopped. The ENIAC took on a more significant role in the field of mathematics.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Prior to physical computers, the word computer was used to refer to people who performed calculations. These people were generally women. If it were not for the war, computers today would be more behind. Computers would still be built because the designs of the computer were made even before the war, but they would unlikely be built until there was an immediate benefit from it. Without the war, possibly the need for calculations in air and space travel would have instead been the first major accelerator for the development of computers.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={Colossus} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Inaccuracies in the Movie</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              In the movie, many parts gave the wrong impression when shown. One minor difference was that in the movie, the machine was called Christopher, but in real life, the machine was actually called Bombe. One of the more major inaccuracies would be how the movie creates the illusion that Alan Turing himself created the Bombe just by himself. The movie very quickly mentions that the design of “Christopher” was inspired by a Polish cryptographer, and one scene shows one of his teammates giving Alan Turing an idea that could improve the design. However, they happen so quickly that most viewers won't even recognize them the first time watching. The repeated use of the lines said by the movie’s Alan Turing like, “my machine will work,” and how most scenes show him working in isolation give the idea that Turing was not assisted by others.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Another inaccuracy related to the Bombe in the movie was regarding the commander at Bletchley Park. The movie portrays the commander, Alastair Dennister, as someone who does not fully support Alan Turing’s work of building the Bombe. However, an article by The Guardian mentions how the commander is “completely misrepresented” in the movie. As viewers, most people will believe by watching the movie, that Alastair Dennister really did not support Alan Turing’s work, and will fail to realize how he was in support of Turing’s work, and that he was “one of the great heroes of Bletchley Park” (The Guardian). It is really unfair for Denniston that the character representing him is represented incorrectly in a negative way just for people to assume that Alan Turing had to work through lots of pressure because of him.

              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={TheImitationGame} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
            </div>
              
          </Route>
          <Route path={currLink+"/Other_Contributions"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard BombeTitleFIRSTCOMPUTER_Unit2ChoiceBoard'>Other Contributions</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>Although the movie mainly focused on Alan Turing's contributions on the Bombe, what the movie called as "Christopher," the movie did touch very briefly on some of Alan Turing's other contributions. The following are three examples.</p>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Universal Turing Machine</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The Universal Turing Machine was a design that Alan Turing published in a journal in 1936 before World War II started. In the movie, Joan Clarke briefly mentions reading about the design in one of Turing’s publications when working with Alan Turing one night. The Universal Turing Machine was thought of by Alan Turing when working on the Entscheidungsproblem problem by Hilbert. The primary goal of the design was to create a general way of how algorithms or programs would work. Turing machines start with a pattern of 1s and 0s, and move forward and backward over the pattern, making changes as it goes. The purpose of the machine can be changed by altering the instructions at the start of the take which is also written using a string of 1s and 0s.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The Turing machine would result in answers to problems in 1s and 0s. A video by Computerphile describes what the machine is capable of really well: the machine can perform “how much math we can do, not by using our gut feelings or intuitions, but just by following rules very very carefully…by following an algorithm.” The biggest significance of publishing the Universal Turing Machine was that it set out the foundation for the field of computer science that is so popular today. Anything computable on a Turing Machine is what real-world computers today can do.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={UniversalTuringMachine} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Artificial Intelligence Test</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              During an investigation, the movie shows one of the investigators asking Alan Turing about differentiating between machines and humans. That scene highlights Alan Turing’s Artificial Intelligence test which he called the Turing test. In the journal Computing Machinery and Intelligence, he asks the question “Can machines think.” To answer that question, there would be a judge who would need to unknowingly determine whether or not a subject is a machine or a human. The significance of the Turing Test was that researchers continued to push themselves to build computers that could pass the Turing Test and many in history recently have been able to fool judges.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={TuringTest} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Automatic Computing Engine</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The movie also shows Alan Turing working on a machine alone in his home for the NPL. What happened was after World War II, Alan Turing became part of the National Physics Laboratory in London. Alan Turing had designed his Automatic Computing Engine (ACE), but NPL would not let the full machine be built so a smaller simpler PilotACE was built. Since Alan Turing was unhappy with NPL that his original design would not be built, he left. Jim Wilkinson then took charge, but Wilkinson would not be successful if it were not for Turing’s legacy. Turing had left the complete description of the machine allowing Jim Wilkingson to improve on the design. Alan Turing had grounded the path for creating computers that could calculate in seconds.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={AutomaticComputingEngine} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
            </div>
              
          </Route>
          <Route path={currLink+"/Legacy"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Legacy</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Alan Turing’s life had been significantly affected by his homosexuality. In 1952, Turing was caught doing homosexual acts, and since being homosexual was illegal in the UK at the time, Turing would either be imprisoned or he would have to take unsafe hormonal therapy. Since Turing wanted to work on his computers, he chose to take hormonal therapy which slowly affected his concentration to the point he could not focus on improving his work.              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Because of his homosexuality, his security clearance was removed and he could not work for the Government Communications Headquarters on the code-breaking activities he had been involved in during the war. Alan Turing also faced public humiliation. Turing was unable to receive recognition for much of his work at the time, but in 2009 he received an apology from the British government, and he received a royal pardon in 2013. Who knows how behind computers would be today without him. Below is a brief one-sentence summary of the legacies of his work on what was mentioned in the previous pages.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Below is a brief one-sentence summary of the legacies of his work on what was mentioned in the previous pages.
              </p>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'><b>The Bombe: </b>By making the Bombe, Alan Turing showed that codes can be broken automatically using machines, inspired other innovators about the potential of building machines for automation, and he was able to shorten World War II by 2-4 years saving millions of people.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'><b>Universal Turing Machine: </b>The Universal Turing Machine helped form the foundation for the field of computer science, and anything that computers today can do can be done on a Turing machine.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'><b>Artificial Intelligence Test: </b>As computers advance more and more, the Turing Test today is used to determine the point where computers are able to give responses indistinguishable from humans.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'><b>Automatic Computing Engine: </b>The basis of Alan Turing’s design was used by other innovators and he was able to ground the path for creating computers that could calculate in seconds.
              </p>
            </div>
              
          </Route>
          <Route path={currLink+"/References"}>
          <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>References</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              1941: Timeline of Computer History: Computer History Museum. 1941 | Timeline of Computer History | Computer History Museum. (n.d.). Retrieved January 1, 2023, from https://www.computerhistory.org/timeline/1941/</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              A&amp;E Networks Television. (2020, July 22). Alan Turing. Biography.com. Retrieved January 1, 2023, from https://www.biography.com/scientist/alan-turing</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Black Bear Pictures. (2014). The Imitation Game</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              BBC. (2019, June 5). D-Day: What happened during the landings of 1944? BBC News. Retrieved January 1, 2023, from https://www.bbc.com/news/world-48513108</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Britannica, T. (2022, August 16). Enigma. Encyclopædia Britannica. Retrieved January 1, 2023, from https://www.britannica.com/topic/Enigma-German-code-device</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Burton, K. (2020, July 31). The scientific and technological advances of World War II: The National WWII Museum: New Orleans. The National WWII Museum | New Orleans. Retrieved January 1, 2023, from https://www.nationalww2museum.org/war/articles/scientific-and-technological-advances-world-war-ii</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Computerphile. (2014, August 29). Turing machines explained - computerphile. YouTube. Retrieved January 1, 2023, from https://www.youtube.com/watch?v=dNRDvLACg5Q</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Copeland, P. J. (2012, June 19). Alan Turing: The codebreaker who saved 'millions of lives'. BBC News. Retrieved January 1, 2023, from https://www.bbc.com/news/technology-18419691</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Cox, H. (2018, November 28). Cracking stuff: How Turing beat the Enigma. Science and Engineering. Retrieved January 1, 2023, from https://www.mub.eps.manchester.ac.uk/science-engineering/2018/11/28/cracking-stuff-how-turing-beat-the-enigma/</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Craig'n'Dave. (2018, April 5). Aqa A'Level the importance of the Universal Turing Machine. YouTube. Retrieved January 1, 2023, from https://www.youtube.com/watch?v=W7G4c-aT4mI</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              De Mol, L. (2018, September 24). Turing machines. Stanford Encyclopedia of Philosophy. Retrieved January 1, 2023, from https://plato.stanford.edu/entries/turing-machine/</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Ellis, S. R. (2013). A cryptography primer. Computer and Information Security Handbook, 35–58. https://doi.org/10.1016/b978-0-12-803843-7.00003-x</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Encyclopædia Britannica, inc. (2019, April 4). Bombe. Encyclopædia Britannica. Retrieved January 1, 2023, from https://www.britannica.com/topic/Bombe</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Encyclopædia Britannica, inc. (2022, December 22). Charles Babbage. Encyclopædia Britannica. Retrieved January 1, 2023, from https://www.britannica.com/biography/Charles-Babbage </p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Encyclopædia Britannica, inc. (n.d.). Computer designer. Encyclopædia Britannica. Retrieved January 1, 2023, from https://www.britannica.com/biography/Alan-Turing/Computer-designer </p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Enigma machine. Brilliant.org. (n.d.). Retrieved January 1, 2023, from https://brilliant.org/wiki/enigma-machine</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Guardian News and Media. (2014, November 27). Portrayal of imitation game 'baddy' is inaccurate, says family. The Guardian. Retrieved January 1, 2023, from https://www.theguardian.com/film/2014/nov/27/imitation-game-alastair-denniston-inaccurate-family</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              History of the Enigma. Crypto Museum. (2022, April 18). Retrieved January 1, 2023, from https://www.cryptomuseum.com/crypto/enigma/hist.htm</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Jacobson, R. (2014, November 28). 8 things you didn't know about Alan Turing. PBS. Retrieved January 1, 2023, from https://www.pbs.org/newshour/science/8-things-didnt-know-alan-turing</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              King's College, Cambridge. Capturing Cambridge. (n.d.). Retrieved January 1, 2023, from https://capturingcambridge.org/centre/kings-parade/chapel-kings-college-cambridge/</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Markoff, J. (2011, November 7). It started digital wheels turning. The New York Times. Retrieved January 1, 2023, from https://www.nytimes.com/2011/11/08/science/computer-experts-building-1830s-babbage-analytical-engine.html#:~:text=The%20machine%20on%20the%20drawing,inventor%20envisioned%20but%20never%20built</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Merriam, A. (2022, December 8). On the doctoral thesis of David Hilbert. Medium. Retrieved January 1, 2023, from https://www.cantorsparadise.com/on-the-doctoral-thesis-of-david-hilbert-978708766389</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              NPLdigital. (2012, March 8). Piloting computing - Alan Turing's automatic computing engine. YouTube. Retrieved January 1, 2023, from https://www.youtube.com/watch?v=cEQ6cnwaY_s</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Science and Technology 2 min read. (n.d.). Enigma encoding machine. National Museums Scotland. Retrieved January 1, 2023, from https://www.nms.ac.uk/explore-our-collections/stories/science-and-technology/enigma-encoding-machine/#:~:text=This%20Enigma%20machine%20is%20of,this%20famous%20code%20maker%20here.</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Security encyclopedia. What is a Bombe? (n.d.). Retrieved January 1, 2023, from https://www.hypr.com/security-encyclopedia/bombe#:~:<br/>text=The%20name%20Bombe%20generally%20refers,was%20used%20for%20the%20encryption.</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Swaine, M. R., & Paul , A. (2022, September 6). ENIAC. Encyclopædia Britannica. Retrieved January 1, 2023, from https://www.britannica.com/technology/ENIAC</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              TEDEducation. (2016, April 25). The Turing test: Can a computer pass for a human? - Alex Gendler. YouTube. Retrieved January 1, 2023, from https://www.youtube.com/watch?v=3wLqsRLvV-c</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The turing-welchman bombe. The National Museum of Computing. (n.d.). Retrieved January 1, 2023, from https://www.tnmoc.org/bombe</p>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard referencesFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Wall, B. (2017, April 17). Computers and chess - A history. Chess.com. Retrieved January 1, 2023, from https://www.chess.com/article/view/computers-and-chess---a-history </p>
            </div>
          </Route>
          
        </Switch>
        
      </div>
      
    </div>
    </Router>
    
  )
}

export default First_Computer




// <VerticalTimeline>
//             {firstComputerTimelineElements.map((element) => {
//                 let isWorkIcon = element.icon==="work";
//                 return (
//                   <VerticalTimelineElement 
//                     key={element.key}
//                     date={element.date}
//                     dateClassName = "dateFIRSTCOMPUTER_Unit2ChoiceBoard"
//                     iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
//                   >
//                     <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img>
//                     <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.imageDescription}
//                     </p>
//                     <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
//                     <h5 className='vertical-timeline-element-subtitle'>
//                       {element.location}
//                     </h5>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description1}
//                     </p>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description2}
//                     </p>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description3}
//                     </p>
//                   </VerticalTimelineElement>
//                 );
//               })}
//            </VerticalTimeline>
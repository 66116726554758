import '../../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

// import CinemaAsAWitnessToModernHistory from './Cinema/CinemaAsAWitnessToModernHistory';
// import GradShow2023 from './GradShow2023/GradShow2023';
// import BandFinalProject from './BandFinalProject/BandFinalProject';
import SeatingSelection from './SeatingSelection/SeatingSelection';

function APS111() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/University_Projects_Home/Fall2023/APS111">
            <div>APS111 Home</div>
          </Route>
          <Route path="/University_Projects_Home/Fall2023/APS111/Seating_Selection">
            <SeatingSelection></SeatingSelection>
          </Route>
          <Route path="/School_Projects_Home/Grade12/GradShow2023">
            {/* <GradShow2023></GradShow2023> */}
          </Route>
          <Route path="/School_Projects_Home/Grade12/BandFinalProject">
            {/* <BandFinalProject></BandFinalProject> */}
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default APS111
import '../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CinemaAsAWitnessToModernHistory from './Cinema/CinemaAsAWitnessToModernHistory';
import GradShow2023 from './GradShow2023/GradShow2023';
import BandFinalProject from './BandFinalProject/BandFinalProject';

function Grade12() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/School_Projects_Home/Grade12">
            <div>Grade12 - works</div>
          </Route>
          <Route path="/School_Projects_Home/Grade12/Cinema_As_A_Witness_To_Modern_History">
            <CinemaAsAWitnessToModernHistory></CinemaAsAWitnessToModernHistory>
          </Route>
          <Route path="/School_Projects_Home/Grade12/GradShow2023">
            <GradShow2023></GradShow2023>
          </Route>
          <Route path="/School_Projects_Home/Grade12/BandFinalProject">
            <BandFinalProject></BandFinalProject>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default Grade12
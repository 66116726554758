import React from 'react';

import NavBar from './NavBar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import firstComputerTimelineElements from './firstComputerTimelineElements';
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import Home from './Home';

import DifferenceEngine from './images/1200px-Babbage_Difference_Engine.jpg';
import TuringComputer from './images/Bletchley-replica-bombe.jpg';
import Z3Computer from './images/zuse-z3-2.jpg';
import KingsCollegeLondon from './images/Screenshot-2021-02-28-at-18.33.13.png';
import DavidHilbert from './images/1_ahy12g2hFP21x7YolyQ_Ug.jpeg';
import UniversalTuringMachine from './images/artificialmusclecomputer1.jpg';
import PrincetonUniversity from './images/960x0.jpg';
import BletchleyParkCodeBreakers from './images/7244576-6479081-image-a-38_1544459200655.jpg';
import Hut8 from './images/_Hut_8_,_Bletchley_Park_-_geograph.org.uk_-_1592868.jpg';
import Tunny from './images/Screenshot 2023-01-01 155959.jpg';
import TuringHomosexual from './images/15.jpg';
import NPLLogo from './images/NPLLogo_360x222.png';
import TuringTest from './images/Turing-Diagram-159676.png';
import ChessProgram from './images/turing07-computer.jpg';
import CourtDocumentTuring from './images/sentencesm.png';
import TuringFigure from './images/1200px-Alan_Turing-1024x768.jpg';
import RoyalPardon from './images/s960_alan-turing-pardon.jpg';
import ENIAC from './images/Screenshot 2023-01-01 171958.jpg';
import ManchesterBaby from './images/large_cd0639_026_101203_GWW_RMG_Baby_computer.jpg';
import Enigma from './images/Enigma_(crittografia)_-_Museo_scienza_e_tecnologia_Milano.jpg';
import UBoat from './images/U-boat-FEATURED.jpg';
import Colossus from './images/Screenshot 2023-01-01 173658.jpg';
import TheImitationGame from './images/Screenshot 2023-01-01 173959.jpg';
import AutomaticComputingEngine from './images/ae0c545448af596a29cb5e175124bf42.jpg';


import TwoBP from './images/002BandProj.jpg';
import ThreeBP from './images/003BandProj.png';
import FourBP from './images/004BandProj.jpg';
import FiveBP from './images/005BandProj.jpg';
import BP6 from './images/006BandProj.jpg';
import BP7 from './images/007BandProj.jpg';
import BP8 from './images/008BandProj.jpg';
import BP9 from './images/009BandProj.jpg';
import BP10 from './images/010BandProj.jpg';
import BP11 from './images/011BandProj.jpg';
import BP12 from './images/012BandProj.jpg';
import BP13 from './images/013BandProj.jpg';
import BP14 from './images/014BandProj.jpg';
import BP15 from './images/015BandProj.jpg';
import BP16 from './images/016BandProj.jpg';
import BP17 from './images/017BandProj.jpg';
import BP18 from './images/018BandProj.jpg';
import BP19 from './images/019BandProj.jpg';
import BP20 from './images/020BandProj.jpg';
import BP21 from './images/021BandProj.jpg';
import BP22 from './images/022BandProj.jpg';
import BP23 from './images/023BandProj.jpg';
import BP24 from './images/024BandProj.jpg';
import BP25 from './images/025BandProj.jpg';
import BP26 from './images/026BandProj.jpg';
import BP27 from './images/027BandProj.jpg';
import BP28 from './images/028BandProj.jpg';
import BP29 from './images/029BandProj.jpg';
import BP30 from './images/030BandProj.jpg';


function First_Computer() {

  let workIconStyles = {background: "#06D6A0"};
  let schoolIconStyles = {background: "#f9c74f"};

  let firstComputerTimelineElements = [
    {
        id: 1,
        title: "Grade 9 Band Trip",
        location: "",
        description1:
            "During the grade 9 band trip, I performed in the concert band, jazz band, and wind ensemble, and all combined created an outstanding experience. Since I was a part of all three, my experience was as best as it could get because that is how I was able to spend time sharing music the most. I remember feeling important when being called to play, especially in those smaller groups like jazz and wind ensemble. I also remember this being the first time having all the excitement to perform music to an audience outside of our Winnipeg community. Thinking that really made me pay even more attention to the music because I wanted to help show how amazing we can be. It is like that feeling when you want to really bring up and showcase your roots. All my music development had been in Winnipeg, as a part of the Acadia band, and when going out to a completely new community, I really wanted to help show just how great we are. These memories are significant because this really shifted my thinking to put more dedication into my music playing. I thought that if I want to help show how great we are to a community so many hours away by bus, then that has to start from home. This means that in rehearsals I would always give all my attention to playing so I can improve as an individual and as a part of the ensemble. This leads to the whole ensemble improving and we can show that we are even better.",
        description2: "Another reason that this memory is important is that COVID had really taken away a lot of our high school experiences that would be spent together. The grade 9 band trip is one of the only significant memories I have of my early high school experience. It was a time that I could spend time doing all sorts of unique activities with others. I tried so many different foods, and there was a smoothie I still remember buying, that I hated every sip, but I still ended up drinking the whole thing, right after coming back from West Edmonton Mall. I remember interacting with a bunch of different friends, with one group at the West Edmonton Mall, trying the rides, then I was with another group buying lunch quickly and rushing out since jazz had to perform, and also with another group where we had snowball fights when we went to the Edmonton Zoo. This memory is deep in my head because the interactions I made with others during this trip made it so easy to talk with so many people coming to FRC from the band program because of an already existing prior connection. I became even closer to people in the band program.",
        description3: "",
        reference1: "https://www.britannica.com/biography/Charles-Babbage",
        reference2: "https://www.nytimes.com/2011/11/08/science/computer-experts-building-1830s-babbage-analytical-engine.html#:~:text=The%20machine%20on%20the%20drawing,inventor%20envisioned%20but%20never%20built.",
        date: "1810s to 1834",
        icon: "work",
        image: TwoBP,
        imageDescription: "",
    },
    {
        id: 2,
        title: "Grade 10 Jazz Audition",
        location: "",
        description1:
            "For the grade 10 Jazz Audition, I remember auditioning with Sotiris, Aieshini, and Yewon. We had a rehearsal first at Sotiris’s house, and then at Yewon’s house. For hours we played, had snacks, played games, and had fun conversations. This memory has endured with me because, during this time, we were not able to play together as much, but when meeting up and making music together happening; I felt joy. When we were all stuck at home, trying to keep ourselves busy, this time together with a small group of people brought back the comfort of spending quality time with each other. This memory is also important to me because I realized just how enjoyable it can be to find a small group of people and perform together. This really encouraged me to create music together with small groups. I found that the time together ends up being more than just playing. Yes, playing is a really enjoyable part, but we would end up talking and getting to know each other better, and that helps form more meaningful connections with others.",
        description2: "",
        description3: "",
        date: "1936 to 1945",
        icon: "work",
        imageDescription: "",
        reference1: "https://www.britannica.com/biography/Alan-Turing/Computer-designer",
    },
    {
      id: 3,
      title: "Last Post",
      location: "",
      description1:
          "I remember earlier this year, I went to Bonnycastle School and performed the last post at their remembrance day ceremony. At first, I really did not want to perform because I was way too nervous to play in front of an entire school. It was not until the day before that I wanted to do it. On the day of the ceremony, I remember driving out to the school about an hour early. I went into the music room and the music teacher let me practice and become comfortable before school started. The school had called all the students to the gym, and I saw all the kids looking amazed to see an instrument. When the time came when I had to play, I took a deep breath and began. As all the kids were walking out, they all came to me saying how they loved seeing a live musician play.",
      description2: "This memory is deep in my memory because it was a moment I was really scared to play. Remembrance Day is such a serious event and I felt really nervous that I would make a mistake. However, I pushed my scared thoughts aside, and I accomplished something which made me feel great, especially after hearing all the appreciation from the children. The memory is important because it showed me how much people appreciate music. All the young kids at Bonneycastle never see someone play live music for them, and in the end when they were all so happy to see me perform, even though I was not perfect, I realized how I could make someone’s day more joyful just by playing an instrument. The teachers there had told me that the children would talk about this the whole day that someone came to their school to play an instrument.",
      description3: "",
      date: "1941",
      icon: "work",
      image: ThreeBP,
      imageDescription: "",
      reference1: "https://www.computerhistory.org/timeline/1941/",
  },
  {
    id: 4,
    title: "Senior Jazz Festival",
    location: "",
    description1:
        "Another memory that I carry is when FRC senior jazz performed at the festival in the Centre Culturel Franco-Manitobain place. I remember picking up Mahen, Zeyu, Sami, and Garry, and then I drove to the Centre Culturel Franco-Manitobain place where we had the festival. At the festival, I remember Mr. Ferguson coming to meet us to give some encouraging words. We sat down and listened to other jazz bands performed, and then we performed on the big stage of the theatre. In the end, I remember stuffing my trunk with all our instruments. We even put Lucy’s bass into my car. When we closed the trunk, I remember I left the keys to the car at the bottom of all our instruments. We then had to unload all the instruments, and we put them all back. Then Mahen, Sami, Zeyu, Garry, and I went to Santa Lucia Pizza and we shared the night together till late night. ",
    description2: "This memory is endured because I remember that we had all spent a lot of time working together to improve the music we were performing at the Jazz Festival. After the performance felt satisfactory, I enjoyed celebrating our hard work by sharing the moment with some of my jazz mates, who also happen to be some of my closest friends. The memory is important to me because I felt that the time put into making something beautiful, like our jazz performance is really worth it. While my friends and I were having dinner, we talked about our performance, and it felt good to just appreciate all the effort we had put in prior to the performance.",
    description3: "",
    date: "1945",
    icon: "work",
    image: FourBP,
    imageDescription: "",
    reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 5,
  title: "Grade 12 Band Trip",
  location: "",
  description1:
      "The Grade 12 band trip was a time when we were all able to spend time with everyone from the band for an entire week. During this trip, an enjoyable memory was at the Niagara Falls Convention Center. I remember during the time when I was not playing and we were not watching, I would walk up to students from different band programs, and ask them where they are from and how they were enjoying. I found out that most people I talked to came from Toronto and the Greater Toronto Area, but some people came from Ottawa and Quebec. In the end, I was able to even talk to someone who came from British Columbia.",
  description2: "This memory endures with me because I found it fascinating to get to know new people, who have grown up with different experiences than me. I remember it being very interesting to learn about how some other schools’ band program works, how their bands are divided, how long the students have played their instruments, and so much more. It really made me feel good that even though we are students so far away (Winnipeg to Toronto area), we could all say that we enjoyed developing as a musician during high school, alongside all our other academics. This memory is important to me because it is a reminder to myself to not be scared to talk to others. At the start, when arriving at the convention center, I saw other bands come out of buses, and I was really curious about where they all came from. I was too scared to ask at first, but after I started talking, I realized that they were excited to talk to me as well. Best of all, we formed short but enjoyable interactions.",
  description3: "",
  date: "1948",
  icon: "work",
  image: FiveBP,
  imageDescription: "",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
  ];

  let AlanTuringTimeline = [
    {
        id: 1,
        title: "The joy of making music",
        location: "Cambridge, England",
        description1:
            "One of the best lessons I have learned is just how fun it can be to make music together. Throughout these past few years, I have felt many moments where I really appreciate the time spent making music with others. One of the first moments I felt this was during COVID when I was starting to realize that my most memorable moments at Acadia were all linked to making good music, especially from the Acadia Jazz Ensemble and Wind Ensemble. Another time which I described earlier was when Sotiris, Aieshini, Yewon, and I rehearsed for the jazz auditions together. I felt the same joy after the Jazz Festival a few months ago, after the jazz concert a few days ago, and after so many more events.",
        description2: "This is why I don’t step down when someone wants to make music with me. For example, I am part of Sanskriti, which is a Bengali Cultural Society of Manitoba. There, we perform many cultural acts, songs, and dances. Sometimes, I am called to create some music with another friend of mine and we would perform. Sometimes I have played the trumpet, and other times I have played the piano. Every time, however, I have felt appreciation for the time we spent creating music because they all end up being very enjoyable times spent together.",
        description3: "",
        reference1: "https://www.biography.com/scientist/alan-turing",
        reference2: "",
        date: "1931 to 1934",
        icon: "work",
        image: KingsCollegeLondon,
        imageDescription: "Image of King's College in 1930s",
    },
    {
        id: 2,
        title: "Find Alternatives",
        location: "King's College, Cambridge, England",
        description1:
            "During COVID, there were so many ways that affected our opportunities to develop as a musician. However, Mrs. Ferguson, you have worked very hard to do your best to find alternatives so we could keep improving as musicians. For example when we played drums during grade 10 so we could keep playing music. Also, when you organized all those guest musicians who spoke to us in teams about a lot of different topics. The way you showed us that no matter the situation, you found alternative ways to help us improve made me learn that when an obstacle comes away that hinders my goals, I should find alternatives. Instead of wasting time, hoping for the obstacle to eventually disappear, I could still improve myself by finding other ways different than how I thought things would go before.",
        date: "1935",
        icon: "work",
        image: DavidHilbert,
        imageDescription: "An image of David Hilbert",
        reference1: "https://www.britannica.com/biography/Alan-Turing/Computer-designer",
    },
    {
      id: 3,
      title: "Overcome Fears",
      location: "King's College, Cambridge, England",
      description1:
          "There were some moments during the band program when I felt very scared going to perform. For example, when I did some jazz solos, whether in front of the jazz band alone or if it was on stage in front of an audience. Another time was when I very scared was right before performing the Last Post at Bonnycastle for their Remembrance Day ceremony. However, after finishing those scary moments I always felt proud of myself that I stepped out of my comfort zone. These moments encourage me to keep trying to overcome my fears if it helps me to do something that makes me feel proud.",
      date: "1936",
      icon: "work",
      image: UniversalTuringMachine,
      imageDescription: "A simple diagram of the Universal Turing Machine",
      reference1: "https://www.computerhistory.org/timeline/1941/",
  },
  {
    id: 4,
    title: "Perform",
    location: "United States of America",
    description1:
        "After performing the Last Post at Bonnycastle at their Remembrance Day ceremony, all the kids were amazed at seeing a live musician play an instrument in front of them. I was very shocked that something as simple as performing could bring so much joy to others. I have performed before, but this performance was a solo I had. It felt more personal. Seeing the kids so amazed made me inspired to keep performing music, even outside of performances that are part of the Band Program. I was shown that I should keep playing music to keep others happy",
    date: "1938",
    icon: "work",
    image: PrincetonUniversity,
    imageDescription: "Image of Princeton University Today",
    reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 5,
  title: "Don’t settle for the minimum",
  location: "Bletchley Park, United Kingdom",
  description1:
      "Another thing I learned was that we should not just settle for the minimum. In this analogy, I’m not using minimum to say that the minimum sucks, but rather to show that we can always do more to grow even more.  For example, I enhanced my musical development by joining the Jazz Program as well. Here I grew even more as a musician, but I also was introduced to a whole new set of experiences, and a journey I would travel with a special group of people. Not only jazz, but I also took part in the provincial honour bands. I noticed that whatever I did to enhance my musical journey, brought me even more joy because of all the experiences that came with it. I want to take this with me for the rest of my life to remind myself that I should not be worried about doing more, but rather, I should be excited to see the journey that may come my way.",
  date: "1939",
  icon: "work",
  image: BletchleyParkCodeBreakers,
  imageDescription: "An image of codebreakers working at Bletchley Park",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 6,
  title: "Rhythms",
  location: "Bletchley Park, United Kingdom",
  description1:
      "As well as many non-musical lessons, my ability to comprehend musical rhythm has improved. Each new tune that I learn comes with a whole new unique sequence of rhythms. Some of the strategies which really helped me to grasp a rhythm are to slow down the tempo a lot, then count out loud while subdividing, and clapping the rhythm with my hand. This came into use more so in jazz because that's where I encountered more complicated rhythms.",
  description2: "",
  date: "1941",
  icon: "work",
  image: Hut8,
  imageDescription: "An image of Hut 8 at Bletchley Park, where Alan Turing worked in",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 7,
  title: "Proper Embouchure",
  location: "Bletchley Park, United Kingdom",
  description1:
      "Although I started playing the trumpet in grade 7, I had played with an incorrect mouthpiece positioning which I did not notice until grade 10 where I struggled to play my instrument for more than a few measures at a time. I used to play with the mouthpiece half on my lip, creating unnecessary tension on my lip. I had a few lessons with Mr. Ferguson, and those lessons changed my playing completely. As I practiced my instrument over the summer I felt more and more comfortable playing with the correct embouchure positioning. My main problem was that I did not align my lips properly with the mouthpiece. That said, by fall when I had to play at school again, it felt comfortable to the point I forgot I had used to play with incorrect mouthpiece positioning.",
  description2: "",
  date: "1942",
  icon: "work",
  image: Tunny,
  imageDescription: "An image of the a recording of the start of a Tunny Message taken from www.AlanTuring.net",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 8,
  title: "Teamwork",
  location: "England",
  description1:
      "Through playing my instrument as a part of the jazz band and symphonic band, I have learned the importance of teamwork. Sometimes, for example, I would be stuck on a rhythm, and I would ask Sami how a specific rhythm goes. This would help me to pick up my instrument and start playing correctly. Other times, Sami would ask me where we are after a long set of rests, and I would be able to point out where we are. Even though we try our best to be attentive and put all our efforts ourselves, being part of a team helps us grow even more by guiding each other. ",
  date: "1945",
  icon: "work",
  image: TuringHomosexual,
  imageDescription: "An image of Alan Turing being recognized as \"Champions of Pride\"",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 9,
  title: "Transfer of Learning",
  location: "London, England",
  description1:
      "In jazz, we talked about this quite a bit, but I learned how important transfer of learning is to our musicality. When we are introduced to songs or exercises, we would learn some concepts. These concepts are then able to be applied to the next songs or exercises we work on. This is important because if we did not carry the knowledge we learned already, then we would struggle with new pieces all the time. However, we are able to pick up small details and apply them to the new piece.",
  date: "1945",
  icon: "work",
  image: NPLLogo,
  imageDescription: "Logo of National Physics Laboratory London",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
{
  id: 10,
  title: "Listening and Balance",
  location: "London, England",
  description1:
      "A major concept of music learning that I learned is showing balance. Sometimes when playing an instrument, we want more of something, and at other times, we do not want as much. Every time we tune and hold our notes, alongside listening to whether or not we are in tune, we also try to develop a rich, round, sound as a whole. I have learned to listen to the volume, tone, and amount of sound provided by the other instruments, and then make a decision of what level of that I should maintain when I do play.",
  date: "1948-1950",
  icon: "work",
  image: TuringTest,
  imageDescription: "A simple diagram of the Turing Test",
  reference1: "https://www.computerhistory.org/timeline/1941/",
},
  ];

  const currLink = "/School_Projects_Home/Grade12/BandFinalProject/BFP/Project";

  const homeLink = "/School_Projects_Home/Grade12/Cinema_As_A_WItness_To_Modern_History/Unit2_ChoiceBoard"

  return (
    <Router>
      <div className='mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard'>
      <NavBar></NavBar>
      <div className="mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard">
        <Switch>
          <Route exact path={currLink}>
            <h1>Click one of the links on the left to get started!</h1>
          </Route>
          <Route  path={currLink+"/Favourite_Memories"}>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Favourite Memories</h1>
            <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard'>The following are some of my favourite memories in my band journey!</p>
            <div className='HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard'>
            {firstComputerTimelineElements.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                  <VerticalTimelineElement 
                    key={element.key}
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img>
                    <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.imageDescription}
                    </p>
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
                    <h5 className='vertical-timeline-element-subtitle'>
                      {element.location}
                    </h5>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </div>
            
          </Route>
          <Route  path={currLink+"/What_I_Learned"}>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>What I Learned</h1>
            <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard'></p>
            <VerticalTimeline>
            {AlanTuringTimeline.map((element) => {
                let isWorkIcon = element.icon==="work";
                return (
                  <VerticalTimelineElement 
                    key={element.key}
                    // date={element.date}
                    dateClassName = "dateFIRSTCOMPUTER_Unit2ChoiceBoard"
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                  >
                    {/* <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img> */}
                    {/* <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.imageDescription}
                    </p> */}
                    <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
                    {/* <h5 className='vertical-timeline-element-subtitle'>
                      {element.location}
                    </h5> */}
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description1}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description2}
                    </p>
                    <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
                      {element.description3}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
           </VerticalTimeline>
          </Route>
          <Route path={currLink+"/Favourite_Pieces"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard BombeTitleFIRSTCOMPUTER_Unit2ChoiceBoard'>Favourite Pieces</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'></p>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              
              
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>It’s Just Talk</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              I really enjoyed It’s Just Talk because it was a jazz piece where 4th Trumpet had a really prominent part. Usually in jazz, my part is not really the main focus, but that one piece really highlighted me. It felt nice to play a part that can be heard. Not just that, but since I was so open, and all my mistakes could be heard, I was able to listen and adjust my playing to make my blend better with the other instruments. I was able to see how articulation was so important and how even minor changes to the articulation can make the sound much different. I was also able to see and feel how the harmony accompanied the melody I was playing because many times I’m at the harmony end accompanying the melody. Overall, this piece is one of my favourites because it really helped me grow as a jazz musician.
              </p>
              <br></br>
              {/* <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
                The Enigma is based on a simpler substitution encryption method where each letter is assigned to another. However, what added complexity to the Enigma machine was that each time a letter was mapped, the entire assignment of letters would be changed. This rearrangement of assigned letters was based on the internal wiring itself to change, altering the current flow in the wires.
              </p> */}
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                {/* <img src={Enigma} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img> */}
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Catch as Catch Can</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Catch as Catch Can was a tune I remember playing when auditioning for jazz. For this tune, Sotiris, Yewon, Aieshini, and I got together and spent hours working on this tune together. When I heard the Intermediate Jazz perform this tune at Niagara Falls, and at the jazz concert here a few days ago, a surge of memories flooded into my mind. I felt like I was sitting down in Sotiris’s backyard just working on this tune, and brought back all the fun time we had together, not just playing our instruments, but having snacks together, and sharing conversation as well. This also reminds me of having to audition outside because of COVID and was a tune that really made me feel excited when all we did was try and keep busy at home. The excitement probably came from the fast pace of the song.              </p>
              <br></br>
              {/* <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The invention of the Bombe by Turing is believed to have shortened the war by 2-4 years and since millions of people died from the war each year, the Bombe possibly may have saved 14-21 million lives. Without the Bombe, the D-Day landings would have been delayed, when the UK, US, Canada, and France, attacked German forces on the northern coast of France in 1944. The more the delay, the more time Hitler would have to prepare for more attacks.
              </p> */}
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                {/* <img src={TuringComputer} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img> */}
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Sanctuary</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Sanctuary, which we played in the symphonic band is definitely one of the most beautiful pieces of music I have played. What really made this piece one of my favourites is how well our symphonic band played the piece. I remember there would be some run-throughs of the music where I would just get lost in the music completely and forget to come in. This did not happen much, because I was focused on my part too, but the rare occasion it did happen. And no wonder it happened, when everyone played the music, it was a feeling of safety, a floating feeling, which felt so calm. I felt like I wanted to stay in that state and forget about any other tensions that I might have. The way that the song is able to do that for me puts this song one of my favourites.              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                {/* <img src={UBoat} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img> */}
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Alleluia! Laudamus Te</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              This piece which we performed at Nationals in Niagara Falls is one of my favourites because I had a part that was independent from the rest of the trumpets. This really made me appreciate my part because I would have to be more attentive to where I would come in since everyone else around me played at different spots. There are some moments which I would look forward to such as my eighth note sequence after the oboe started that sequence. There would also be parts I would play alongside the French horns. My independent role in this piece made me appreciate the song and made it one of my favourites.              </p>
              <br></br>
              {/* <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Prior to physical computers, the word computer was used to refer to people who performed calculations. These people were generally women. If it were not for the war, computers today would be more behind. Computers would still be built because the designs of the computer were made even before the war, but they would unlikely be built until there was an immediate benefit from it. Without the war, possibly the need for calculations in air and space travel would have instead been the first major accelerator for the development of computers.
              </p> */}
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                {/* <img src={Colossus} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img> */}
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Every Summer Night</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Every Summer Night was a tune that we did not play for a long time but is definitely one that I enjoyed and added to my playlist. I really enjoyed the warm texture that was added when we played the melody with the flugelhorn. Additionally, I loved the time signature changes when the piece would go from 4/4 time to ¾ and the melodic texture would change. The subtle aspect of the song made me feel nostalgic and reminded me of the great time shared throughout my whole jazz journey starting from the grade 9 Acadia Jazz Band. Even after the concert where we performed this tune, I found myself constantly humming this tune bringing me a calm feeling.              </p>
              <br></br>
              {/* <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Another inaccuracy related to the Bombe in the movie was regarding the commander at Bletchley Park. The movie portrays the commander, Alastair Dennister, as someone who does not fully support Alan Turing’s work of building the Bombe. However, an article by The Guardian mentions how the commander is “completely misrepresented” in the movie. As viewers, most people will believe by watching the movie, that Alastair Dennister really did not support Alan Turing’s work, and will fail to realize how he was in support of Turing’s work, and that he was “one of the great heroes of Bletchley Park” (The Guardian). It is really unfair for Denniston that the character representing him is represented incorrectly in a negative way just for people to assume that Alan Turing had to work through lots of pressure because of him.

              </p> */}
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                {/* <img src={TheImitationGame} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img> */}
              </div>
            </div>
              
          </Route>
          <Route path={currLink+"/Other_Contributions"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
            <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard BombeTitleFIRSTCOMPUTER_Unit2ChoiceBoard'>Other Contributions</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>Although the movie mainly focused on Alan Turing's contributions on the Bombe, what the movie called as "Christopher," the movie did touch very briefly on some of Alan Turing's other contributions. The following are three examples.</p>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Universal Turing Machine</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The Universal Turing Machine was a design that Alan Turing published in a journal in 1936 before World War II started. In the movie, Joan Clarke briefly mentions reading about the design in one of Turing’s publications when working with Alan Turing one night. The Universal Turing Machine was thought of by Alan Turing when working on the Entscheidungsproblem problem by Hilbert. The primary goal of the design was to create a general way of how algorithms or programs would work. Turing machines start with a pattern of 1s and 0s, and move forward and backward over the pattern, making changes as it goes. The purpose of the machine can be changed by altering the instructions at the start of the take which is also written using a string of 1s and 0s.
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The Turing machine would result in answers to problems in 1s and 0s. A video by Computerphile describes what the machine is capable of really well: the machine can perform “how much math we can do, not by using our gut feelings or intuitions, but just by following rules very very carefully…by following an algorithm.” The biggest significance of publishing the Universal Turing Machine was that it set out the foundation for the field of computer science that is so popular today. Anything computable on a Turing Machine is what real-world computers today can do.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={UniversalTuringMachine} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Artificial Intelligence Test</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              During an investigation, the movie shows one of the investigators asking Alan Turing about differentiating between machines and humans. That scene highlights Alan Turing’s Artificial Intelligence test which he called the Turing test. In the journal Computing Machinery and Intelligence, he asks the question “Can machines think.” To answer that question, there would be a judge who would need to unknowingly determine whether or not a subject is a machine or a human. The significance of the Turing Test was that researchers continued to push themselves to build computers that could pass the Turing Test and many in history recently have been able to fool judges.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={TuringTest} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
              <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr>
              <h3 className='BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard'>Automatic Computing Engine</h3>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              The movie also shows Alan Turing working on a machine alone in his home for the NPL. What happened was after World War II, Alan Turing became part of the National Physics Laboratory in London. Alan Turing had designed his Automatic Computing Engine (ACE), but NPL would not let the full machine be built so a smaller simpler PilotACE was built. Since Alan Turing was unhappy with NPL that his original design would not be built, he left. Jim Wilkinson then took charge, but Wilkinson would not be successful if it were not for Turing’s legacy. Turing had left the complete description of the machine allowing Jim Wilkingson to improve on the design. Alan Turing had grounded the path for creating computers that could calculate in seconds.
              </p>
              <div className='WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard'>
                <img src={AutomaticComputingEngine} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              </div>
            </div>
              
          </Route>
          <Route path={currLink+"/Favourite_Performance"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Favourite Performance</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              My favourite performance has been the final jazz concert. This is because of all the hard work from my jazz journey all the way from grade 9 Acadia Jazz Band which led to this moment. It was amazing to see how the depth of each band’s playing had improved when hearing one band to the next. By saying that, I am not saying that the youngest band had played poorly, in fact, they’ve played amazingly. It was seeing how the musicality of each band’s playing improved as members become more experienced with jazz. 
</p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Another part of the concert which I enjoyed was hearing the tunes that we have been playing throughout the years of our jazz journey. It brought back memories of the tunes we enjoyed practicing, and also some of the tunes that we had to struggle with more. 
              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Another part of the concert I really enjoyed is hearing individuals play. When listening to the other jazz bands play from the audience, I was amazed by some players and how professional they play. I shouldn’t be saying some, really everyone sounded very professional. Considering that these are junior high and high school students playing such high-level music, I am amazed. 
              </p>
              {/* <hr className='BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard'></hr> */}
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>It truly felt phenomenal to show our very best in this final concert. We were able to showcase all the skills we learned and share them with our community. Even though the tunes we played we might have only played for a short amount of time, we developed skills from other tunes and exercises, and through transfer learning, we were able to apply those skills to our final performance. 

              </p>
              <br></br>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>Finally, this performance really made me think of how great of an experience I have had as a part of the Fort Richmond Band program, and how I am going to miss all this as I graduate high school. It really made me appreciate this entire opportunity, truly with one of the best programs in all of Canada. I have been inspired to come back in future years to keep supporting the FRC Band Program.

              </p>
              <br></br>
              
            </div>
              
          </Route>
          <Route path={currLink+"/Keep_Music_In_Life"}>
            <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>How will I keep Music in my Life</h1>
              <p className='paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard'>
              Music will always be a key part of my life. Of course, my playlist is going to have lots of songs that I am going to listen to. However, music is going to stay in my life in more ways than that. For example, for cultural programs, I want to keep making music using my trumpet or piano and share that with my community. During university, I also want to find a group of people to just continue playing our instruments with. I have started appreciating more how enjoyable attending concerts can be so that is also another way I want to keep music in my life. Finally, FRC’s band program had been so changing that I want to stay connected by coming back and visiting for concerts. This will help me connect deep down straight at my roots because this is where I grew up.

</p>
              <br></br>
              
            </div>
              
          </Route>
          <Route path={currLink+"/Pictures"}>
          <div className='HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard'>
              <h1 className='HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard'>Pictures</h1>
              <img src={BP6} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP7} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP8} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP9} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP10} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP11} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP12} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP13} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP14} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP15} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP16} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP17} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP18} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP19} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP20} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP21} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP22} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP23} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP24} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP25} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP26} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP27} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP28} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP29} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              <img src={BP30} className='WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard'></img>
              
              
            </div>
          </Route>
          
        </Switch>
        
      </div>
      
    </div>
    </Router>
    
  )
}

export default First_Computer




// <VerticalTimeline>
//             {firstComputerTimelineElements.map((element) => {
//                 let isWorkIcon = element.icon==="work";
//                 return (
//                   <VerticalTimelineElement 
//                     key={element.key}
//                     date={element.date}
//                     dateClassName = "dateFIRSTCOMPUTER_Unit2ChoiceBoard"
//                     iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
//                   >
//                     <img src={element.image} className="timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard"></img>
//                     <p id='ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.imageDescription}
//                     </p>
//                     <h3 className='vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard'>{element.title}</h3>
//                     <h5 className='vertical-timeline-element-subtitle'>
//                       {element.location}
//                     </h5>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description1}
//                     </p>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description2}
//                     </p>
//                     <p id='descriptionFIRSTCOMPUTER_Unit2ChoiceBoard'>
//                       {element.description3}
//                     </p>
//                   </VerticalTimelineElement>
//                 );
//               })}
//            </VerticalTimeline>
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import SchoolProjectsHome from './pages/SchoolProjects/SchoolProjectsHome';
import UniversityProjectsHome from './pages/UniversityProjects/UniversityProjectsHome';
import People from './pages/People/People';

// import computerImg from './images/Bletchley-replica-bombe.jpg';
import ArnabImage from './images/ArnabImage.jpeg';

const App = () => {
  return (
    <div className='wholeBody'>
      <Router>
            <div className="content">
              <Switch>
                <Route exact path="/">
                  <h1>WELCOME TO ARNAB'S SITE!</h1>
                  <h2>Website under construction, but check out some of my school projects!</h2>
                  <Link to={"/School_Projects_Home/Grade12/BandFinalProject/BFP"}>
                      <h3>Band Final Project</h3>
                  </Link>
                  <Link to={"/School_Projects_Home/Grade12/Cinema_As_A_Witness_To_Modern_History/Unit2_ChoiceBoard"}>
                      <h3>Cinema Final Project</h3>
                  </Link>
                  <Link to={"/School_Projects_Home/Grade12/GradShow2023"}>
                      <h3>Grad Show 2023</h3>
                  </Link>
                  <img className='ArnabImage' src={ArnabImage} alt="Seating Arrangements Image"></img>                
                </Route>
                <Route path="/School_Projects_Home">
                  <SchoolProjectsHome></SchoolProjectsHome>
                </Route>
                <Route path="/University_Projects_Home">
                  <UniversityProjectsHome></UniversityProjectsHome>
                </Route>
                <Route path="/People">
                  <People></People>
                </Route>
                <Route path="/another_issue">
                  <h1>ANOTHER ISSUE</h1>
                </Route>
              </Switch>
            </div>
            
          </Router>
    </div>
    
    
  );
}

export default App;
import '../../../App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Grade12 from './Grade12/Grade12';
// import Fall2023 from './Fall2023/Fall2023';

import ArnabTeaganImage from './media/ArnabTeaganImage.jpeg';

function Teagan() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path="/People/Teagan">
          <img className='ArnabImage' src={ArnabTeaganImage} alt="Seating Arrangements Image"></img>
          </Route>
        </Switch>
      </div>
      
    </Router>
  )
}

export default Teagan